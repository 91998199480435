<template>
  <b-modal id="in-progress-modal"
      header-bg-variant="light"
      header-text-variant="dark"
      size="sm"
      :visible="show"
      hide-header
      hide-footer
      no-close-on-backdrop  
      content-class="progress-style"  
      hide-backdrop  
    >
    
    <b-row>
      <b-col cols="12">
        <b-spinner small type="grow" /> {{ message }}
      </b-col>      
    </b-row>
    <b-row class="mt-3" v-if="isStopable">
      <b-col cols="12">
        <div class="d-flex justify-content-center">
          <b-button size="sm" variant="danger" @click="stop">{{ $t('button.stop') }}</b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'InProgressModal',
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    isStopable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    message() {
      return this.label? this.label : this.$t('progress.save');
    }
  },
  methods: {
    stop() {
      this.$emit('cancel');

    }
  }
}
</script>

